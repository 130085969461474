body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("assets/background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
}

main {
  flex: 1;
  flex-direction: column;
}
